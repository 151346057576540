/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue';
import DefaultLayout from './layouts/Default.vue';
import DashboardLayout from './layouts/Dashboard.vue';
import DashboardRTLLayout from './layouts/DashboardRTL.vue';
import router from './router';
import axios from 'axios';
// import './plugins/click-away'
import JsonCSV from 'vue-json-csv';
// import Datepicker from 'vuejs-datepicker';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment';

import './scss/app.scss';
import store from './store';

Vue.prototype.$http = axios;
Vue.prototype.moment = moment;

Vue.use(Antd);
Vue.use(BootstrapVue);

axios.defaults.withCredentials = true
axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("Logout");
      return router.push("/sign-in");
    }
  }
});

Vue.config.productionTip = false;

// Adding template layouts to the vue components.
Vue.component('layout-default', DefaultLayout);
Vue.component('layout-dashboard', DashboardLayout);
Vue.component('layout-dashboard-rtl', DashboardRTLLayout);
Vue.component('downloadCsv', JsonCSV);
// Vue.component('vuejsDatepicker', Datepicker)

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
