const state = {
  userEmail: null,
  access_token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.access_token && !!state.userEmail,
  access_token: (state) => state.access_token,
  userEmail: (state) => state.userEmail,
};

const actions = {
  async SetToken({ commit }, {access_token, userEmail}) {
    await commit("setToken", access_token);
    await commit("setUserEmail", userEmail);
  },
  async Logout({ commit }) {
    commit("logout");
  },
};

const mutations = {
  setUserEmail(state, userEmail) {
    state.userEmail = userEmail;
  },
  setToken(state, access_token) {
    state.access_token = access_token;
  },
  logout(state) {
    state.userEmail = null;
    state.access_token = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
